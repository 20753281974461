<template>
  <div class="fix-height home__top-banner">
    <div class="hero-banner">
      <Swiper
        ref="banner"
        v-bind="swiperOption"
        :key="swiperKey"
        class="mySwiper"
      >
        <SwiperSlide v-for="(item, idx) in bannerData" :key="item.id" class="swiper-slide">
          <BannerItem :item="item" :index="idx" @click="handleClickBanner(item)" />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, EffectCoverflow, Navigation } from 'swiper'
import useHeroBanner from '~/composables/useHeroBanner'
const BannerItem = defineAsyncComponent(() => import('~/components/desktop/pages/home/hero-banner/banner-item.vue'))

const swiperKey = ref(0)

const { fetchBanner, handleClickBanner } = useHeroBanner()
const swiperOption = reactive({
  grabCursor: true,
  initialSlide: 0,
  observer: true,
  loop: false,
  slidesPerView: 1,
  slidesPerGroup: 1,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  centeredSlides: true,
  spaceBetween: 0,
  pagination: {
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  modules: [Autoplay, Pagination, EffectCoverflow, Navigation]
})
const { data: bannerData } = await useAsyncData('banner', async () => {
  return await fetchBanner()
})

watch(
  () => bannerData.value,
  (newData) => {
    if (newData && newData.length > 1) {
      setTimeout(() => {
        swiperOption.loop = true
        swiperKey.value++
      }, 500)
    }
  },
  { immediate: true }
)

</script>

<style lang="scss" scoped src="~/public/assets/scss/components/desktop/pages/home/hero-banner/index.scss"></style>
