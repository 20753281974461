export const schemaSeoConfig = ($domainUrl: string, $imageUrl: string) => [
  JSON.stringify(
    {
      '@context': 'https://schema.org',
      '@type': 'Casino',
      url: `${$domainUrl}/`,
      name: 'TX88',
      alternateName: 'TX88 – Đỉnh Cao Trải Nghiệm Giải Trí Trực Tuyến!',
      '@id': `${$domainUrl}/`,
      logo: `${$imageUrl}/assets/brand/logo.svg`, // Provide the logo URL
      image: `${$imageUrl}/assets/brand/seo-image.webp?h=${generateHash(3)}`, // Provide an image URL
      description: 'TX88 – Hệ thống giải trí toàn diện, mang đến hàng trăm trò chơi hấp dẫn từ tài xỉu, game bài đến thể thao. Trải nghiệm ngay hôm nay để nhận ưu đãi độc quyền và dịch vụ hàng đầu!',
      telephone: '+84965486760',
      email: 'tx88win@gmail.com',
      founder: 'TX88',
      address: {
        '@type': 'PostalAddress',
        streetAddress: '8 Võ Chí Công, Hoà Xuân, Cẩm Lệ, Đà Nẵng, Việt Nam',
        postalCode: '550000',
        addressCountry: 'VN'
      },
      sameAs: [
        'https://www.facebook.com/tx88win',
        'https://www.youtube.com/@tx88win',
        'https://twitter.com/tx88win',
        'https://www.pinterest.com/tx88win/',
        'https://www.tumblr.com/tx88win',
        'https://vimeo.com/tx88win',
        'https://www.reddit.com/user/tx88win/',
        'https://soundcloud.com/tx88win',
        'https://www.quora.com/profile/tx88win',
        'https://www.zillow.com/profile/tx88win',
        'https://link.space/@tx88win',
        'https://bikeindex.org/users/tx88win',
        'https://skinnerpalomaaliana633.systeme.io/',
        'https://medium.com/@tx88win/about',
        'https://gravatar.com/tx88win'
      ]
    }

  ),
  JSON.stringify(
    {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      name: 'TX88',
      alternateName: 'TX88 – Đỉnh Cao Trải Nghiệm Giải Trí Trực Tuyến!',
      url: `${$domainUrl}/`,
      '@id': `${$domainUrl}/`,
      logo: `${$imageUrl}/assets/brand/logo.svg`, // Provide the logo URL
      image: `${$imageUrl}/assets/brand/seo-image.webp?h=${generateHash(3)}`, // Provide an image URL
      description: 'TX88 – Hệ thống giải trí toàn diện, mang đến hàng trăm trò chơi hấp dẫn từ tài xỉu, game bài đến thể thao. Trải nghiệm ngay hôm nay để nhận ưu đãi độc quyền và dịch vụ hàng đầu!',
      telephone: '+84965486760',
      priceRange: '$10-$1000000',
      email: 'tx88win@gmail.com',
      founder: 'TX88',
      address: {
        '@type': 'PostalAddress',
        streetAddress: '8 Võ Chí Công, Hoà Xuân, Cẩm Lệ, Đà Nẵng',
        postalCode: '550000',
        addressCountry: 'VN'
      },
      review: {
        '@type': 'Review',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: '4.9',
          bestRating: '5'
        },
        author: {
          '@type': 'Person',
          name: 'TX88'
        }
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: 14.0583,
        longitude: 108.2215
      },
      openingHoursSpecification: {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: [
          'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
        ],
        opens: '00:00',
        closes: '23:59'
      },
      sameAs: [
        'https://www.facebook.com/tx88win',
        'https://www.youtube.com/@tx88win',
        'https://twitter.com/tx88win',
        'https://www.pinterest.com/tx88win/',
        'https://www.tumblr.com/tx88win',
        'https://vimeo.com/tx88win',
        'https://www.reddit.com/user/tx88win/',
        'https://soundcloud.com/tx88win',
        'https://www.quora.com/profile/tx88win',
        'https://www.zillow.com/profile/tx88win',
        'https://link.space/@tx88win',
        'https://bikeindex.org/users/tx88win',
        'https://skinnerpalomaaliana633.systeme.io/',
        'https://medium.com/@tx88win/about',
        'https://gravatar.com/tx88win'
      ]
    }
  )
]

function generateHash(length: number) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let hash = ''

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    hash += characters[randomIndex]
  }

  return hash
}
