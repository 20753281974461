<template>
  <div class="top-nohu">
    <SectionTitle title="Đua top phá hũ" link="/game/no-hu" is-see-more />
    <div class="top-nohu__container">
      <template v-for="(item, index) in listGameJackpotHome?.slice(0, 6)" :key="index">
        <CommonGamesItemGame
          class="item-game"
          :item="item"
          :class="`${item?.class ? item?.class : ''}`"
          :is-play-now="false"
        />
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useJackpot } from '~/composables/game/useJackpot'
import CommonGamesItemGame from '~/components/common/game-item.vue'
import { useGame } from '~~/composables/game/useGame'
const SectionTitle = defineAsyncComponent(() => import('@/components/common/title-section.vue'))
const { fetchListGameJackpot } = useGame()
const { fetchJackpot } = useJackpot()
const { data: listGameJackpotHome } = await useAsyncData('fetchListGameJackpot', fetchListGameJackpot)
onMounted(async () => {
  await fetchJackpot()
})
</script>
<style lang="scss" scoped src="~/public/assets/scss/components/mobile/pages/home/top-nohu/index.scss"></style>
