import { SPORTS_URLS, PAGE_URLS } from '~/config/page-url'
import { LOBBY_GAME_URLS } from '~/config/page-url'
import { PATH_IMAGES_ICON } from '~/constants/path'
import { useContactStore } from '~/store/contact'
import { IContact } from '~~/types/seo'
export const useFooterNavigation = () => {
  const { $config, $pinia } = useNuxtApp()
  const store = useContactStore($pinia)

  const formatContact = computed(() => store.contact?.reduce((acc, item: IContact) => {
    acc[item.name.toLowerCase().replace(/\s/g, '')] = { url: item.url }
    return acc
  }, {} as Record<string, { url: string }>) as any)

  const contactNavigation = computed(() => [
    {
      title: 'Thể thao',
      subCategories: [
        { title: 'Lịch thi đấu', link: PAGE_URLS.SCHEDULES },
        { title: 'Cược thể thao', link: SPORTS_URLS.INDEX }
      ]
    },
    {
      title: 'Trò chơi',
      subCategories: [
        { title: 'Bắn cá', link: LOBBY_GAME_URLS.BAN_CA },
        { title: 'Slots', link: LOBBY_GAME_URLS.SLOTS_GAME },
        { title: 'Nổ hũ', link: LOBBY_GAME_URLS.NO_HU },
        { title: 'Game bài', link: LOBBY_GAME_URLS.GAME_BAI },
        { title: 'Lô đề', link: LOBBY_GAME_URLS.LO_DE },
        { title: 'Table games', link: LOBBY_GAME_URLS.TABLE_GAME },
        { title: 'Game nhanh', link: LOBBY_GAME_URLS.QUICK_GAMES }
      ]
    },
    {
      title: 'Về chúng tôi',
      subCategories: [
        { title: `Giới thiệu ${$config.public.BRANCH_NAME}`, link: `${PAGE_URLS.INTRODUCTION}` },
        { title: 'Điều kiện - Điều khoản', link: `${PAGE_URLS.POLICY}` },
        { title: 'Bảo mật', link: `${PAGE_URLS.SECURITY}` },
        { title: 'Chơi game có trách nhiệm', link: `${PAGE_URLS.RESPONSIBILITY}` },
        { title: 'Quy định chung', link: `${PAGE_URLS.RULE}` }
      ]
    },
    {
      title: 'Hỗ trợ 24/7',
      subCategories: [
        {
          title: 'Trực tuyến 24/7',
          type: 'BLANK',
          link: store.contact?.find((item: IContact) => item.name === 'livechat')?.url
        },
        {
          title: 'Telegram CSKH',
          type: 'BLANK',
          link: store.contact?.find((item: IContact) => item.name === 'cskh')?.url
        },
        { title: 'Hướng dẫn Đăng ký', type: '', link: `${PAGE_URLS.GUIDE_REGISTER}` }
      ]
    },
    {
      title: 'Thông tin',
      subCategories: [{ title: 'Khuyến mãi /Sự kiện', link: PAGE_URLS.PROMOTION }]
    }
  ] as any)

  const supportMenus = computed(() => [
    {
      name: 'Live chat 24/7',
      url: `${formatContact.value?.livechat?.url}`,
      activeUrl: [],
      isLiveChat: true,
      icon: PATH_IMAGES_ICON + 'icon-livechat.webp',
      iconMB: PATH_IMAGES_ICON + 'icon-livechat-mb.svg'
    },
    {
      name: `Telegram ${$config.public.BRANCH_NAME}`,
      url: `${formatContact.value?.telegram?.url}`,
      activeUrl: [],
      icon: PATH_IMAGES_ICON + 'icon-telegram.webp',
      iconMB: PATH_IMAGES_ICON + 'icon-telegram-mb.svg'
    },
    {
      name: 'Cộng đồng',
      url: `${formatContact.value?.cskh?.url}`,
      activeUrl: [],
      icon: PATH_IMAGES_ICON + 'icon-community.webp',
      iconMB: PATH_IMAGES_ICON + 'icon-community-mb.svg'
    },
    {
      name: 'Hotline',
      url: `tel:${formatContact.value?.hotline?.url}`,
      activeUrl: [],
      icon: PATH_IMAGES_ICON + 'icon-phone.webp',
      iconMB: PATH_IMAGES_ICON + 'icon-phone-mb.svg'
    },
    {
      name: 'Fanpage',
      url: `${formatContact.value?.fanpage?.url}`,
      activeUrl: [],
      icon: PATH_IMAGES_ICON + 'icon-fb.webp',
      iconMB: PATH_IMAGES_ICON + 'icon-fb-mb.svg',
      className: 'icon-fanpage'
    }
  ] as any)

  return {
    contactNavigation,
    supportMenus
  }
}
