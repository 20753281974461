import { useUserApi } from '~/api/user'
import { useAppStore } from '~/store/app'
import { useValidate } from '~/composables/useValidate'
import { MODAL_TYPES } from '~/config/constant'
import { decryptWithAES } from '~/helper'
interface LoginForm {
  username: string;
  password: string;
}
export const useLogin = () => {
  const { LOG_IN } = useUserApi()
  const { $axios, $alertMsg, $bvModal, $device, $isIOS } = useNuxtApp()
  const { $pinia } = useNuxtApp()
  const route = useRoute()
  const { setCurrentUser } = useAppStore($pinia)
  const { validateRefs } = useValidate()
  const username = ref<(HTMLElement & { resetValidate: () => boolean }) | null>(null)
  const password = ref<(HTMLElement & { resetValidate: () => boolean }) | null>(null)
  const isLoading = ref<boolean>(false)
  const formData: LoginForm = reactive({
    username: '',
    password: decryptWithAES('')
  })
  watch(() => formData.username, (value) => {
    if (value !== '') {
      sessionStorage.setItem('username', value)
    }
  })
  onMounted(() => {
    formData.username = sessionStorage.getItem('username') || ''
    window.addEventListener('beforeunload', () => {
      sessionStorage.removeItem('username')
    })
  })

  const submit = async (isFromHeader: boolean = false) => {
    // Check popup is oped, skip call submit api
    const popupType = route.query.popup || null
    if (popupType && isFromHeader) { return }
    const validateList: string | any[] = reactive([username, password])
    const isValid: boolean = validateRefs(validateList)
    if (isValid && !isLoading.value) {
      const result = await login(formData)
      if ($device.isMobileOrTablet && result) {
        setTimeout(() => window.scrollTo(0, $isIOS ? -100 : 0), 100)
      }
    }
  }

  const login = async (formData: LoginForm) => {
    try {
      isLoading.value = true
      const res = await ($axios as any).$post(LOG_IN, formData)
      if (res && res.status === 'OK') {
        window.dataLayer = window.dataLayer || []
        if (typeof dataLayer !== 'undefined') {
          dataLayer.push({
            event: 'formSubmitted',
            formName: 'Form_Login'
          })
        }
        setCurrentUser(res.data[0])
        $bvModal.hide(MODAL_TYPES.LOGIN)
        username.value?.resetValidate()
        password.value?.resetValidate()
        isLoading.value = false
        return true
      } else {
        setCurrentUser(null)
        $alertMsg('error', res.message)
      }
    } catch (error) {
      setCurrentUser(null)
    } finally {
      isLoading.value = false
    }
    return false
  }

  return {
    username,
    password,
    formData,
    isLoading,
    submit
  }
}
