import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import { TYPE_METHOD, PAGE_PARAMS } from '~/constants/common'
import { LOBBY_GAME_URLS, SPORTS_URLS, PAGE_URLS, ACCOUNT_URLS } from '~/config/page-url'
import { IContact } from '~/types/seo'
import { PATH_IMAGES_ICON } from '~/constants/path'
const PATH_IMAGE = '/assets/images/components/desktop/footer/'

export const getFooterNavigation = () => {
  const { $config, $pinia } = useNuxtApp()
  const store = useAppStore($pinia)
  const { contact } = storeToRefs(store)
  const result = contact?.value?.reduce((acc, item: IContact) => {
    acc[item.name.toLowerCase().replace(/\s/g, '')] = { url: item.url }
    return acc
  }, {} as Record<string, { url: string }>)

  return {
    NAVIGATIONS: [
      {
        title: 'Thể thao',
        subCategories: [
          { title: 'Lịch thi đấu', link: PAGE_URLS.SCHEDULES },
          { title: 'Cược thể thao', link: SPORTS_URLS.INDEX }
        ]
      },
      {
        title: 'Trò chơi',
        subCategories: [
          { title: 'Bắn cá', link: LOBBY_GAME_URLS.BAN_CA },
          { title: 'Slots', link: LOBBY_GAME_URLS.SLOTS_GAME },
          { title: 'Nổ hũ', link: LOBBY_GAME_URLS.NO_HU },
          { title: 'Games bài', link: LOBBY_GAME_URLS.GAME_BAI },
          { title: 'Lô đề', link: LOBBY_GAME_URLS.LO_DE },
          { title: 'Table games', link: LOBBY_GAME_URLS.TABLE_GAME },
          { title: 'Quay số', link: LOBBY_GAME_URLS.QUAY_SO },
          { title: 'Games nhanh', link: LOBBY_GAME_URLS.QUICK_GAMES }
        ]
      },
      {
        title: 'Về chúng tôi',
        subCategories: [
          { title: `Giới thiệu ${$config.public.BRANCH_NAME}`, link: `${PAGE_URLS.INTRODUCTION}` },
          { title: 'Điều kiện - Điều khoản', link: `${PAGE_URLS.INTRODUCTION}/${PAGE_PARAMS.POLICY}` },
          { title: 'Bảo mật', link: `${PAGE_URLS.INTRODUCTION}/${PAGE_PARAMS.SECURITY}` },
          { title: 'Chơi games có trách nhiệm', link: `${PAGE_URLS.INTRODUCTION}/${PAGE_PARAMS.RESPONSIBILITY}` },
          { title: 'Quy định chung', link: `${PAGE_URLS.INTRODUCTION}/${PAGE_PARAMS.RULE}` }
        ]
      },
      {
        title: 'Hỗ trợ 24/7',
        subCategories: [
          { title: 'Trực tuyến 24/7', type: 'BLANK', link: `${result?.livechat?.url}` },
          { title: 'Telegram CSKH', type: 'BLANK', link: `${result?.telegram?.url}` },
          { title: 'Hướng dẫn Đăng ký', link: `${PAGE_URLS.GUIDE_REGISTER}` }
        ]
      },
      {
        title: 'Thông tin',
        subCategories: [{ title: 'Khuyến mãi /Sự kiện', link: PAGE_URLS.PROMOTION }]
      }
    ]
  }
}

export const DEPOSIT_LIST = [
  {
    key: 'nicepayBanks',
    title: 'CodePay',
    image: `${PATH_IMAGE}codepay.svg`,
    link: ACCOUNT_URLS.DEPOSIT_DA,
    isMaintain: false
  },
  {
    key: 'p2p',
    title: 'P2P',
    image: `${PATH_IMAGE}p2p.svg`,
    link: ACCOUNT_URLS.P2P,
    isMaintain: false

  },
  {
    key: 'crypto_v2',
    title: 'Tiền ảo',
    image: `${PATH_IMAGE}crypto.svg`,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    isMaintain: false
  },
  {
    key: 'momos',
    title: 'Ví điện tử',
    image: `${PATH_IMAGE}digital-wallet.svg`,
    link: ACCOUNT_URLS.DEPOSIT_MOMO,
    isMaintain: false

  },
  {
    key: 'ViettelPays',
    title: 'Viettel Money',
    image: `${PATH_IMAGE}viettel-money.svg`,
    link: ACCOUNT_URLS.DEPOSIT_VIETTEL_PAY,
    isMaintain: false
  },
  {
    key: 'cardList',
    title: 'Thẻ cào',
    image: `${PATH_IMAGE}card.svg`,
    link: ACCOUNT_URLS.DEPOSIT_CARD,
    isMaintain: false
  }
]

export const getFooterContent = () => {
  const { $config } = useNuxtApp()

  return {
    SLOGAN_TITLE: `${$config.public.BRANCH_NAME} - Nhà cái thể thao trực tuyến, Siêu chợ games đổi thưởng đỉnh cao`,
    SLOGAN_DESCRIPTION: `${$config.public.BRANCH_NAME} là trang cá cược thể thao hấp dẫn, cung cấp đa dạng về sản phẩm trò chơi như Thể Thao, Trò Chơi, Casino Trực Tuyến và thưởng hoàn trả cao nhất trên thị trường.`,
    VIEW_MORE: 'xem thêm',
    COPY_RIGHT: `Copyright © 2024 Powered By ${$config.public.BRANCH_NAME} All Rights Reserved.`
  }
}
export const getFooterContact = () => {
  const { $config, $pinia } = useNuxtApp()
  const store = useAppStore($pinia)
  const { contact } = storeToRefs(store)
  const result = contact?.value?.reduce((acc, item: IContact) => {
    acc[item.name.toLowerCase().replace(/\s/g, '')] = { url: item.url }
    return acc
  }, {} as Record<string, { url: string }>)

  return {
    SUPPORT_MENU: [
      {
        name: 'Live chat 24/7',
        url: `${result?.livechat?.url}`,
        activeUrl: [],
        isLiveChat: true,
        icon: PATH_IMAGES_ICON + 'icon-livechat.webp',
        iconMB: PATH_IMAGES_ICON + 'icon-livechat-mb.svg'
      },
      {
        name: `Telegram ${$config.public.BRANCH_NAME}`,
        url: `${result?.telegram?.url}`,
        activeUrl: [],
        icon: PATH_IMAGES_ICON + 'icon-telegram.webp',
        iconMB: PATH_IMAGES_ICON + 'icon-telegram-mb.svg'
      },
      {
        name: 'Cộng đồng',
        url: `${result?.cskh?.url}`,
        activeUrl: [],
        icon: PATH_IMAGES_ICON + 'icon-community.webp',
        iconMB: PATH_IMAGES_ICON + 'icon-community-mb.svg'
      },
      {
        name: 'Hotline',
        url: `tel:${result?.hotline?.url}`,
        activeUrl: [],
        icon: PATH_IMAGES_ICON + 'icon-phone.webp',
        iconMB: PATH_IMAGES_ICON + 'icon-phone-mb.svg'
      },
      {
        name: 'Fanpage',
        url: `${result?.fanpage?.url}`,
        activeUrl: [],
        icon: PATH_IMAGES_ICON + 'icon-fb.webp',
        iconMB: PATH_IMAGES_ICON + 'icon-fb-mb.svg',
        className: 'icon-fanpage'
      }
    ]
  }
}
export const getFooterAboutUs = () => {
  return {
    INTRODUCE_LIST: [
      {
        name: 'Giới thiệu',
        icon: PATH_IMAGES_ICON + 'icon-introduce.svg',
        url: PAGE_URLS.INTRODUCTION
      },
      {
        name: 'Quy định',
        icon: PATH_IMAGES_ICON + 'icon-policy.svg',
        url: PAGE_URLS.RULE
      },
      {
        name: 'Bảo mật',
        icon: PATH_IMAGES_ICON + 'icon-privacy.svg',
        url: PAGE_URLS.SECURITY
      },
      {
        name: 'Hướng dẫn',
        icon: PATH_IMAGES_ICON + 'icon-desc.svg',
        url: PAGE_URLS.GUIDE
      },
      {
        name: 'Tin tức',
        icon: PATH_IMAGES_ICON + 'icon_news.svg',
        url: PAGE_URLS.NEWS,
        type: 'news'
      }
    ]
  }
}
