import { defineStore, acceptHMRUpdate } from 'pinia'
import { seoApi } from '~/api/seo'
import { IContact } from '~/types/seo'
interface State {
  _contact: IContact[] | null
}
export const useContactStore = defineStore('useContactStore', {
  state: (): State => ({
    _contact: null
  }),
  getters: {
    contact: (state) => state._contact
  },
  actions: {
    setContact(contact: IContact[]) {
      this._contact = contact
    },
    async fetchContact() {
      const { $axios } = useNuxtApp()
      const { BRAND_CONTACT } = seoApi()
      try {
        const { data: response } = await $axios.get(BRAND_CONTACT)
        if (response?.data?.length > 0) {
          this.setContact(response.data as IContact[])
        }
      } catch (error: any) {
        console.log('useContact', error?.response?.data?.message)
      }
    }
  },
  persist: false
})
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useContactStore, import.meta.hot))
}
