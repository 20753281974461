<template>
  <div class="game-item">
    <div class="game-item__wrapper" :class="{ maintain: isMaintain }" @click="onClickGame()">
      <BaseImg
        class="game-item__image"
        :src="item.img"
        :alt="item.name"
        :border-radius="`${radius ? radius : '16'}`"
        lazy
        :wait-lazy="waitLazy"
        :end-lazy="endLazy"
        @load="onLoadImage"
      />
      <div v-if="isLoadedImage && jackpotNumber && showTopJacPot" class="game-item__wrapper--jackpot">
        <AnimateCountUp
          v-if="jackpotNumber !== 0"
          :number="jackpotNumber"
          :class="[osType]"
        />
      </div>
      <div v-if="isPlayNow && !isMaintain" class="game-item__overlay">
        <div class="inner">
          <button>{{ textPlay }}</button>
          <p v-if="item.is_demo" @click.stop.prevent="onClickGame(true)">Chơi thử</p>
        </div>
      </div>
    </div>

    <div v-if="isLoadedImage && jackpotNumber && !hiddenJacPot" class="game-item__jackpot">
      <AnimateCountUp
        v-if="jackpotNumber !== 0"
        :number="jackpotNumber"
        :class="[osType]"
      />
    </div>
    <div v-if="showName" class="game-item__game-name">
      <span>{{ item.name }}</span>
    </div>
    <BaseImg v-if="isMaintain" class="game-item__tags" :src="getThumbImage(item)" />
    <BaseImg
      v-if="getThumbImage(item) && showTag"
      class="game-item__tags"
      :class="[item?.tags?.name.toLowerCase()]"
      :src="getThumbImage(item)"
      :alt="`tags ${item.tags}`"
    />
  </div>
</template>

<script setup lang="ts">
import debounce from 'lodash/debounce'
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import useNavigationGame from '~/composables/useNavigationGame'
import BaseImg from '~/components/common/base-img.vue'
import AnimateCountUp from '~/components/common/animate-count-up.vue'
import { IGameItem } from '~/types/common'
import { PATH_IMAGES_ICON } from '~/constants/path'
const { $pinia, $device } = useNuxtApp()
const store = useAppStore($pinia)
const { jackpot } = storeToRefs(store)
const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
const isLoadedImage = ref(false)
const osType = ref()
const props = withDefaults(
  defineProps<{
    item: IGameItem
    showCoin?: boolean
    showName?: boolean
    isPlayNow?: boolean
    autoWidth?: boolean
    autoHeight?: boolean
    hiddenJacPot?: boolean
    showTopJacPot?: boolean,
    showTag?: boolean,
    radius?: string
    textPlay?: string
    waitLazy?: boolean
    endLazy?: boolean
  }>(),
  {
    showCoin: true,
    showName: false,
    hiddenJacPot: false,
    showTopJacPot: false,
    showTag: true,
    isPlayNow: true,
    autoWidth: false,
    autoHeight: false,
    radius: '12',
    textPlay: 'Chơi Ngay',
    waitLazy: false,
    endLazy: false
  }
)
const fromNumber = ref(props.item?.money)

const jackpotNumber = computed<number>(() => {
  const keyJackpot: string = props.item.partner_game_id
  const value: Record<string, number> = jackpot.value
  return value[keyJackpot] ?? 0
})

const isMaintain = computed<boolean>(() => {
  return props.item?.maintain || props.item?.tags?.name?.toLowerCase() === 'maintenance'
})

const onLoadImage = () => {
  isLoadedImage.value = true
}

const onClickGame = (isDemo?: boolean) => {
  navigationCheckLoggedInAndOpenGame(props.item, isDemo)
}

const getThumbImage = (item: IGameItem) => {
  if (item?.tags) {
    return item?.tags.img
  }
  return ''
}

onMounted(() => {
  osType.value = $device.isDesktop ? 'desktop' : $device.isIos ? 'ios' : 'android'
})
</script>

<style lang="scss" scoped src="public/assets/scss/components/common/game-item.scss"></style>
