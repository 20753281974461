<template>
  <div id="header" class="header">
    <div class="header__container container-custom">
      <div class="header-menu">
        <nuxt-link id="logo" class="logo" :to="PAGE_URLS.HOME">
          <BaseImg relative-src="/assets/brand/logo.webp" alt="logo" @click="navigateToHome" />
        </nuxt-link>
        <nuxt-link id="promotion" class="promotion" :to="PAGE_URLS.PROMOTION">Khuyến mãi</nuxt-link>
      </div>
      <div class="header-button">
        <BaseImg
          id="search"
          class="header-button__search"
          relative-src="/assets/images/icon/icon-search-history.svg"
          alt="search"
          @click="openModalOne(MODAL.SEARCH)"
        />
        <div id="notification-header" class="header-button__notif">
          <BaseImg
            id="bell"
            class="header-button__notif--bell"
            relative-src="/assets/images/components/desktop/icon-notif.svg"
            alt="bell"
          />
        </div>
        <UserNotLogin v-if="!currentUser" />
        <UserLogged v-if="currentUser" />
      </div>
    </div>
    <div class="menu" :class="{ justifyLeft: updatedMenus.filter((menu) => !menu.hidden).length < 5 }">
      <nuxt-link
        v-for="(item, index) in (jsStatus ? updatedMenus : MENUS)"
        v-show="!item?.hidden"
        :id="`link-${item?.name}`"
        :key="index"
        class="menu__item"
        :class="[
          item?.name,
          {
            openSubMenu: openSubMenu === item?.name,
            active:
              route.fullPath === item?.activeUrl ||
              route.fullPath.startsWith(item?.activeUrl) ||
              route.params.type === item?.type ||
              route.path == item?.activeUrl
          }
        ]"
        :to="item.activeUrl"
        @mouseover="onMouseOver(item)"
        @mouseleave="onMouseLeave()"
        @click="onClickSubMenu()"
      >
        <BaseImg class="icon-category" :relative-src="item.image" :alt="item?.name" />
        <span class="menu-title">{{ item.title }}</span>
        <div v-if="item.subMenu && item.subMenu.length" class="icon-down">
          <span class="icon-menu-down" />
        </div>
        <div v-if="item.subMenu && item.subMenu.length" class="sub-menu" :class="item?.name">
          <div class="sub-menu__container" :class="{ gridmenu: item.subMenu.length > 6 }">
            <nuxt-link
              v-for="(subItem, subIndex) in item.subMenu"
              :id="`header-${subItem?.name}`"
              :key="`${index}${subIndex}`"
              class="sub-menu__item"
              :class="[
                subItem?.type,
                {
                  active:
                    subItem.url === route.fullPath ||
                    `${subItem?.alias?.toLowerCase()}` === route.params.type ||
                    (`${PAGE_URLS.CASINO}/${subItem?.alias?.toLowerCase()}` === '/livecasino/all' &&
                      (route.path === '/livecasino/all' || route.path === '/livecasino'))
                }
              ]"
              :to="`${PAGE_URLS.CASINO}/${subItem?.alias?.toLowerCase()}`"
            >
              <div class="sub-menu__item--content">
                <BaseImg class="icon-inactive" :relative-src="subItem.icon" :alt="subItem.name" />
                <div class="text">
                  <p>{{ subItem?.display_name || subItem.alias }}</p>
                </div>
              </div>
              <div v-if="!subItem?.isNotShowLine" class="line" />
            </nuxt-link>
          </div>
        </div>
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { MODAL } from '~/constants/modal'
import BaseImg from '~/components/common/base-img.vue'
import { useAppStore } from '@/store/app'
import UserNotLogin from '~/components/desktop/header/user-not-login.vue'
import UserLogged from '~/components/desktop/header/user-logged.vue'
import { IMenuHeader, ISubMenuHeader } from '~/types/menu.type'
import { useGameStore } from '~~/store/game'
import { LOBBY_GAME_URLS, LOBBY_CASINO_URLS, PAGE_URLS } from '~/config/page-url'
import { IProvider, IItemCongGame } from '~/types/lobby.type'
import { useGame } from '~/composables/game/useGame'
import { useCasino } from '~/composables/casino/useCasino'
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot, currentUser, isLogged } = storeToRefs(store)
const storeGame = useGameStore($pinia)
const { providerGames, providerCasino } = storeToRefs(storeGame)
const router = useRouter()
const openSubMenu = ref('')
const jsStatus = ref(false)
const updatedMenus = ref<IMenuHeader[]>([])
const { fetchCongGame } = useGame()
const { fetchGameCasino } = useCasino()
const { fetchListBank } = useListBank()
const { openModalOne } = useModal()
const { fetchLogoHeader } = useHeader()
const route = useRoute()
const MENUS = computed<IMenuHeader[]>(() => [
  {
    type: 'the-thao',
    name: 'the-thao',
    tab: 'tab-sport',
    title: 'Thể thao',
    activeUrl: '/the-thao',
    image: '/assets/images/home/header/sport.svg'
  },
  {
    type: 'livecasino',
    name: 'casino',
    title: 'Live Casino',
    tab: 'tab-casino',
    activeUrl: PAGE_URLS.CASINO,
    image: '/assets/images/home/header/casino.svg',
    subMenu: providerCasino.value
  },
  {
    name: 'game-type',
    type: 'quay-so',
    tab: 'lottery',
    title: 'Quay số',
    activeUrl: LOBBY_GAME_URLS.QUAY_SO,
    image: '/assets/images/home/header/quay-so.svg'
  },
  {
    name: 'game-type',
    title: 'Nổ hũ',
    type: 'no-hu',
    tab: 'tab-games',
    activeUrl: LOBBY_GAME_URLS.NO_HU,
    image: '/assets/images/home/header/no-hu.svg'
  },
  {
    name: 'game-type',
    title: 'Game bài',
    type: 'game-bai',
    tab: 'tab-games',
    activeUrl: LOBBY_GAME_URLS.GAME_BAI,
    image: '/assets/images/home/header/game-bai.svg'
  },
  {
    name: 'game-type',
    title: 'Lô Đề',
    type: 'lo-de',
    tab: 'tab-games',
    activeUrl: LOBBY_GAME_URLS.LO_DE,
    image: '/assets/images/home/header/lo-de.svg'
  },
  {
    name: 'game-type',
    title: 'table games',
    type: 'table-game',
    tab: 'tab-games',
    activeUrl: LOBBY_GAME_URLS.TABLE_GAME,
    image: '/assets/images/home/header/tables-game.svg'
  },
  {
    name: 'game-type',
    title: 'Bắn cá',
    type: 'ban-ca',
    tab: 'tab-games',
    activeUrl: LOBBY_GAME_URLS.BAN_CA,
    image: '/assets/images/home/header/ban-ca.svg'
  },
  {
    name: 'game-type',
    title: 'Slots',
    type: 'slots',
    tab: 'tab-games',
    activeUrl: LOBBY_GAME_URLS.SLOTS_GAME,
    image: '/assets/images/home/header/slots.svg'
  },
  {
    name: 'game-type',
    title: 'Game Nhanh',
    type: 'game-nhanh',
    tab: 'tab-games',
    activeUrl: LOBBY_GAME_URLS.QUICK_GAMES,
    image: '/assets/images/home/header/game-nhanh.svg'
  }
])

watch(isLogged, () => {
  fetchListBank()
})

const onMouseOver = (item: IMenuHeader) => {
  if (item.subMenu && item.subMenu.length) {
    openSubMenu.value = item?.name
  }
}
const onMouseLeave = () => {
  openSubMenu.value = ''
}

const onClickSubMenu = () => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, 0)
  openSubMenu.value = ''
}

function getSubItemLink(type: string, code: string) {
  switch (type) {
    case 'livecasino':
      return `${PAGE_URLS.CASINO}/${code}`
    case 'games':
      return `${PAGE_URLS.GAME}/${code}`
  }
}

const onClickMenu = (item: IMenuHeader, subItem?: ISubMenuHeader) => {
  const link: any = subItem ? getSubItemLink(item.type, subItem?.alias?.toLowerCase()) : item.activeUrl
  router.push(link)
}

onMounted(async () => {
  jsStatus.value = true
  await fetchLogoHeader()
  await fetchListBank()
})

useAsyncData('fetchCongGame', async () => {
  return await fetchCongGame()
})

useAsyncData('fetchGameCasino', async () => {
  return await fetchGameCasino()
})

function processMenus(menus: IMenuHeader[], providerGame: IItemCongGame[]): IMenuHeader[] {
  const filteredMenus = menus.filter((menu) => menu.type !== 'the-thao' && menu.type !== 'livecasino')
  const filteredSport = menus.filter((menu) => menu.type === 'the-thao')
  const filteredCasino = menus.filter((menu) => menu.type === 'livecasino')
  const processedMenus = filteredMenus.map((menu) => {
    const matchingProvider = providerGame?.find((provider) => provider.alias === menu.type)
    if (matchingProvider) {
      return { ...menu, ...matchingProvider }
    } else {
      return { ...menu, hidden: true }
    }
  })

  return [...filteredSport, ...filteredCasino, ...processedMenus]
}
const navigateToHome = () => {
  router.push({ path: PAGE_URLS.HOME })
  if (route.fullPath === '/') {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}

watchEffect(() => {
  updatedMenus.value = processMenus(MENUS.value, providerGames.value)
})
onMounted(() => {
  updatedMenus.value = processMenus(MENUS.value, providerGames.value)
})
</script>

<style scoped lang="scss" src="public/assets/scss/components/desktop/header/index.scss"></style>
