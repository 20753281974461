import { MODAL_TYPES } from '~~/config/constant'
import { useAppStore } from '~/store/app'
import useModal from '~/composables/useModal'
import { seoApi } from '~/api/seo'

export const useHeader = () => {
  const { $pinia, $axios } = useNuxtApp()
  const { $state } = useAppStore($pinia)
  const { openModalWithNavigate } = useModal()
  const store = useAppStore($pinia)

  const { BRAND_INFO } = seoApi()
  const logoHeader = ref<string | null>('/assets/brand/logo.webp')
  const logoFooter = ref<string | null>('/assets/brand/logo.webp')

  const currentUser = computed(() => {
    return $state.currentUser
  })

  const onClickAuthor = (modalName = MODAL_TYPES.LOGIN, url = 'login') => {
    sessionStorage.removeItem('username')
    sessionStorage.removeItem('phoneRegister')
    sessionStorage.removeItem('usernameLogin')
    openModalWithNavigate(modalName, url)
  }

  const fetchLogoHeader = async () => {
    try {
      if (store.logoHeader) {
        logoHeader.value = store.logoHeader
        logoFooter.value = store.logoFooter
        return
      }

      const { data: response } = await $axios.get(BRAND_INFO)
      if (response.status === 'OK') {
        logoHeader.value = response.data.logo_header
        logoFooter.value = response.data.logo_footer
        store.setLogoHeader(response.data.logo_header)
        store.setLogoFooter(response.data.logo_footer)
      }
    } catch (error: any) {
      console.log('fetchLogoHeader', error?.response?.data?.message)
    }
  }

  return { onClickAuthor, MODAL_TYPES, currentUser, fetchLogoHeader }
}
