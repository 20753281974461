<template>
  <BaseModal :config="config" modal-class="display-name">
    <template #content>
      <div class="modal-display-name">
        <div class="modal-display-name__header">
          <h2>Thêm tên hiển thị</h2>
        </div>
        <CommonFormChangeDisplayName />
      </div>
    </template>
  </BaseModal>
</template>
<script setup lang="ts">
import { MODAL } from '~/constants/modal'
import { MODAL_TYPES } from '~/config/constant'
import useModal from '~/composables/useModal'
const CommonFormChangeDisplayName = defineAsyncComponent(
  () => import('~/components/desktop/modals/change-display-name/change-display-name-form.vue')
)
const BaseModal = defineAsyncComponent(() => import('~/components/common/base-modal.vue'))
const { openModal, closeModal } = useModal()
const config = reactive({
  id: MODAL_TYPES.DISPLAY_NAME,
  centered: true,
  noCloseOnBackdrop: true,
  hideClose: false
})
const onHideModal = () => {
  closeModal(MODAL.DISPLAY_NAME)
}
window.addEventListener('popstate', function () {
  closeModal(MODAL_TYPES.DISPLAY_NAME)
})
</script>
<style lang="scss" scoped src="public/assets/scss/components/desktop/modals/change-display-name/index.scss" />
