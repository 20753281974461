export const appCdnURL = "https://ps25.ps-dev.strangled.net"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"google","content":"notranslate"},{"http-equiv":"Content-Language","content":"vi"},{"name":"og:site_name","content":"Nhà cái PS25"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1.0, viewport-fit=cover, user-scalable=no"}],"link":[],"style":[],"script":[{"src":"https://demo.nanocosmos.de/nanoplayer/api/release/nanoplayer.4.min.js","type":"text/javascript","defer":true}],"noscript":[],"htmlAttrs":{"lang":"vi"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const devPagesDir = null