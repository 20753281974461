<template>
  <ClientOnly>
    <div :class="isRolling ? 'animation-number-cover-rolling' : 'animation-number-cover'">
      <CountUp
        :key="initNumber"
        ref="countUpRef"
        class="animation-number"
        :end-val="endValue"
        :start-val="startValue"
        :duration="duration"
        :autoplay="true"
      />
      <CommonBaseImg v-if="showCoin" src="/assets/images/icon/coin-k.svg" class="icon-coin" alt="icon-coin" />
    </div>
  </ClientOnly>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import CountUp from './count-up.vue'
import CommonBaseImg from '~/components/common/base-img.vue'

const { $device, $config } = useNuxtApp()

const props = defineProps({
  number: {
    type: [Number, String],
    default: 0
  },
  initNumber: {
    type: Number,
    default: 0
  },
  showCoin: {
    type: Boolean,
    show: false
  },
  isRolling: {
    type: Boolean,
    default: false
  }
})

const countUpRef = ref(null)
const startValue = ref(props.initNumber || props.number)
const animationRaise = startValue.value * 0.1
const endValue = ref(Number(props.number) + animationRaise)
const duration = ref(animationRaise * 10 / Number($config.public.JACKPOT_ANIMATION_SPEED))

watch(() => props.initNumber, (newNumber) => {
  if (countUpRef.value) {
    startValue.value = newNumber || props.number
    endValue.value = props.number
    countUpRef.value.restart()
  }
})

const width = computed(() => {
  return String(props.number).length * 12.5
})

</script>

  <style scoped lang="scss">
  .animation-number-cover-rolling {
      align-items: center;
      display: flex;
      gap: 4px;
      .animation-number {
      color: #ffe280;
      height: 20px;
      text-align: right;
      text-transform: uppercase;
    }
    .animation-number {
      ::v-deep(span) {
      font-family: SourceSans3,sans-serif;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem
    }
  }
  }

  .mobile .animation-number-cover {
    display: flex;
    justify-content: center;
    align-items: center;
    .animation-number {
      display: flex;
      align-items: center;
      justify-content: center;
  }
  }

  </style>
