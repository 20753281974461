import { PAGE_URLS } from '~/config/page-url'
export const PLAN_TYPE_SUKIEN_HOANTRA15 = 1
export const listPromotionHome = [
  {
    src: 'assets/images/home/promotion/bonus.webp',
    title: 'Cấp vốn 300%',
    des: 'Nhận ngay 3,000,000\ncho phiếu nạp đầu tiên',
    link: PAGE_URLS.PROMOTION + '/thuong-chao-mung-300'
  },
  {
    src: 'assets/images/home/promotion/return.webp',
    title: 'Hoàn trả vô hạn',
    des: 'Đặt cược càng nhiều,\nnhận thưởng càng lớn',
    link: PAGE_URLS.PROMOTION + '/the-loai/hoan-tra'
  },
  {
    src: 'assets/images/home/promotion/888.webp',
    title: 'Ưu đãi hấp dẫn',
    des: 'Phần thưởng lớn mỗi ngày',
    link: PAGE_URLS.PROMOTION
  },
  {
    src: 'assets/images/home/promotion/vip.webp',
    title: 'Thành viên VIP',
    des: 'Lên VIP liền,\nnhận tiền cực đã',
    link: PAGE_URLS.PROMOTION + '/vip'
  }
]
