<template>
  <div class="live-stream">
    <div :id="'live-' + dataLive.streamName" class="player-wrapper" :class="{ loaded: player && !isLoading }">
      <div
        v-if="player && !isLoading"
        class="loaded"
        @click="handlePlayGame"
      >
        <div class="audio-background" @click.stop="handleSoundChange">
          <BaseImg
            v-if="!muted"
            :relative-src="`${PATH_IMG}icon-muted.webp`"
            alt="icon muted"
            class="icon-sound"
          />
          <BaseImg
            v-else
            :relative-src="`${PATH_IMG}icon-sound-on.webp`"
            alt="icon sound on"
            class="icon-sound"
          />
        </div>
      </div>
      <div class="default-thumb">
        <BaseImg :relative-src="dataLive.backgroudDefault" :alt="'icon ' + dataLive.name" />
      </div>
      <div class="jackpot-section">
        <div class="type">{{ dataLive.name }}</div>
        <div v-if="jackpot" class="jackpot">
          <AnimateCountUp v-if="jackpot" :number="jackpot" :show-coin="false" />
        </div>
      </div>
      <div class="live-badge">
        <BaseImg
          class="dot"
          :relative-src="`${PATH_IMG}dot.webp`"
          alt="icon dot"
        />
        <div class="live-text">
          LIVE
        </div>
        <div class="group-view">
          <BaseImg
            :relative-src="`${PATH_IMG}icon_group.webp`"
            alt="icon group"
          />
        </div>
        <div class="live-view">
          {{ viewers }}
        </div>
      </div>
      <div class="play-button" @click="handlePlayGame">
        <div class="play-overlay">
          <BaseButton class="play-btn">
            Chơi game
          </BaseButton>
        </div>
      </div>
      <div v-if="error && !isLoading" class="replay-button" @click="handleReplay">
        <BaseImg
          relative-src="/assets/images/common/icon-play.svg"
          alt="icon play"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import AnimateCountUp from '~/components/common/animate-count-up.vue'
import BaseButton from '@/components/common/base-button'
import BaseImg from '@/components/common/base-img'
import { useAppStore } from '~/store/app'
import useNavigation from '~/composables/useNavigationGame'
import { useJackpot } from '~/composables/game/useJackpot'
import { PATH_IMG } from '~/constants/home/stream'

const props = defineProps({
  dataLive: {
    type: Object,
    default: null
  }
})

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { navigationCheckLoggedInAndOpenGame } = useNavigation()
const { fetchJackpot } = useJackpot()

const muted = ref(false)
const viewers = ref(0)
const error = ref(false)
const isLoading = ref(true)
let player = null
let viewerInterval = null

const defaultUrl = 'rtmp://bintu-splay.nanocosmos.de/splay'

const defaultServer = reactive({
  websocket: 'wss://bintu-h5live-secure.nanocosmos.de/h5live/authstream',
  hls: 'https://bintu-h5live-secure.nanocosmos.de/h5live/authhttp/playlist.m3u8'
})

const config = reactive({
  source: {
    defaults: {
      service: 'bintu'
    },
    entries: [
      {
        h5live: {
          server: null,
          rtmp: {
            url: null,
            streamname: props?.dataLive?.streamName
          },
          security: {
            jwtoken: ''
          }
        }
      }
    ]
  },
  playback: {
    autoplay: true,
    automute: true,
    muted: true,
    faststart: true,
    bufferTime: 2,
    bufferTimeMax: 4,
    latencyControlMode: 'fastadaptive',
    reconnect: {
      minDelay: 2,
      maxDelay: 10,
      delaySteps: 10,
      maxRetries: 10
    }
  },
  style: {
    displayMutedAutoplay: false,
    controls: false,
    fullScreenControl: false,
    centerView: false,
    poster: props.dataLive.backgroudDefault,
    interactive: true
  },
  events: {
    onError: (e) => {
      error.value = true
      console.error('e', e)
    }
  }
})

async function reinitializePlayer() {
  if (player) {
    player.destroy()
  }
  player = null
  if (!player) {
    await initPlayer()
  }
}

onMounted(() => {
  config.source.entries[0].h5live.server = defaultServer
  config.source.entries[0].h5live.rtmp.url = defaultUrl
  verifyToken()
  initRandomViewers()
})

async function verifyToken() {
  try {
    const response = await fetch(
      'https://api-csn-s.gameland.today/api/v1/stream/',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          groupId: props?.dataLive?.groupId,
          streamId: props?.dataLive?.streamName,
          site: window.location.host
        })
      }
    )
    if (!response.ok) {
      error.value = true
      return
    }
    const result = await response.json()

    const { data } = result

    if (data && data.token) {
      config.source.entries[0].h5live.security.jwtoken = data.token
    }
    if (player) {
      await player.load(config)
    } else {
      await initPlayer()
    }
  } catch (err) {
    error.value = true
    console.error('Error verifying token:', err)
  } finally {
    isLoading.value = false
  }
}

async function initPlayer() {
  if (player) {
    player.destroy()
  }
  const id = `live-${props.dataLive.streamName}`
  player = new window.NanoPlayer(id)
  try {
    await player.setup(config)
  } catch (error) {
    console.error('Player setup failed:', error.message)
  }
}

function handleSoundChange() {
  muted.value = !muted.value
  if (!player) {
    return
  }
  if (muted.value) {
    player.unmute()
  } else {
    player.mute()
  }
}

function randomInitialViewers() {
  const min = 500
  const max = 1000
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function randomAdjustment() {
  const adjustment = Math.floor(Math.random() * (30 - 10 + 1)) + 10
  return Math.random() < 0.5 ? -adjustment : adjustment
}

function initRandomViewers() {
  viewers.value = randomInitialViewers()

  viewerInterval = setInterval(() => {
    let newViewers = viewers.value + randomAdjustment()
    newViewers = Math.min(Math.max(newViewers, 200), 2500)
    viewers.value = newViewers
  }, 5000)
}

function handlePlayGame() {
  navigationCheckLoggedInAndOpenGame(props.dataLive)
}

function handleReplay() {
  isLoading.value = true
  error.value = false
  console.log('Replaying...', player)
  setTimeout(() => {
    if (player) {
      muted.value = false
      player.mute()
      player.play()
      isLoading.value = false
      error.value = false
    } else {
      reinitializePlayer()
      muted.value = false
    }
  }, 3500)
}

function destroyPlayer() {
  if (player) {
    player.destroy()
  }
}

const jackpot = computed(() => {
  const jackpotState = store.jackpot
  return jackpotState && jackpotState[props.dataLive.jackpotId]
    ? jackpotState[props.dataLive.jackpotId]
    : 0
})

onUnmounted(() => {
  destroyPlayer()
  clearInterval(viewerInterval)
})
</script>

<style lang="scss" scoped src="public/assets/scss/components/desktop/pages/home/stream/live.scss"></style>
