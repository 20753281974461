<template>
  <div class="menu-button">
    <div
      v-for="(item, index) in menu"
      :key="index"
      class="menu-item"
      :class="{
        active: checkActive(item, item.activeUrl)
      }"
      @click="clickMenuItem(item)"
    >
      <div
        class="action-wrap__icon"
      >
        <CommonBaseImg
          class="icon"
          :relative-src="item.icon"
          :alt="item.name"
        />
      </div>
      <div class="text">{{ item.name }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import CommonBaseImg from '~/components/common/base-img'
import { PAGE_URLS } from '~/config/page-url'
import { getBottomMenu } from '~/constants/menu'
import { useAppStore } from '~/store/app'
import { useToggleMenu } from '~/composables/useToggleMenu'
import { IMenuBottom } from '~/types/menu.type'

const route = useRoute()
const store = useAppStore()
const { toggleMenuAccount } = useToggleMenu()
const { BOTTOM_MENU } = getBottomMenu()
const menu = computed(() => {
  return BOTTOM_MENU.filter((item) => item.name !== (route.path === PAGE_URLS.HOME ? 'Trang chủ' : 'Khuyến mãi'))
})

const clickMenuItem = (item: IMenuBottom) => {
  if (!item?.url) {
    toggleMenuAccount()
    return
  }
  navigateTo(item.url)
}
const checkActive = (item: IMenuBottom, activeUrl: string) => {
  if (item.url === BOTTOM_MENU[1].url) {
    return false
  } else {
    return (route.fullPath.includes(activeUrl)) || (route.name === item?.type && route?.params?.type !== 'quay-so')
  }
}
</script>
<style scoped lang="scss" src="public/assets/scss/components/mobile/common/menu-bottom/index.scss" />
