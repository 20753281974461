import { defineAsyncComponent } from 'vue'
import { defineNuxtPlugin } from '#app/nuxt'

const components = {
  BModal: defineAsyncComponent(() => import("/Users/yoshi/Documents/Project/Brand/ps25-web/components/common/base/BModal.vue" /* webpackChunkName: "components/b-modal" */).then(c => c.default || c))
}

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const name in components) {
      nuxtApp.vueApp.component(name, components[name])
      nuxtApp.vueApp.component('Lazy' + name, components[name])
    }
  }
})
