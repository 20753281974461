<template>
  <div id="header-mobile" class="header">
    <nuxt-link :to="PAGE_URLS.HOME">
      <BaseImg
        id="logo-mobile"
        relative-src="/assets/brand/logo.webp"
        class="logo"
        alt="logo"
        @click="$router.push(PAGE_URLS.HOME)"
      />
    </nuxt-link>
    <MobileHeaderLoginButton v-if="!currentUser" />
    <MobileHeaderUserLogged v-if="currentUser" />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { PAGE_URLS } from '~/config/page-url'
import { useAppStore } from '@/store/app'
import MobileHeaderLoginButton from '~/components/mobile/header/user-not-login.vue'
import MobileHeaderUserLogged from '~/components/mobile/header/user-logged.vue'
import { useHeader } from '~/composables/useHeader'
const { currentUser, fetchLogoHeader } = useHeader()
const route = useRoute()
const router = useRouter()
const { fetchListBank } = useListBank()
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { isLogged } = storeToRefs(store)

const navigateToHome = () => {
  router.push({ path: PAGE_URLS.HOME })
  if (route.fullPath === '/') {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}

watch(isLogged, () => {
  fetchListBank()
})

onMounted(async () => {
  await fetchLogoHeader()
  await fetchListBank()
})
</script>
<style scoped lang="scss" src="public/assets/scss/components/mobile/header/index.scss" />
