import { default as bankkP9IyJrrxSMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/bank.vue?macro=true";
import { default as bonussaSpJ6Gh8PMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/bonus.vue?macro=true";
import { default as bankBUTcIuCzfgMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/deposit/bank.vue?macro=true";
import { default as cardslGOoJKhZxMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/deposit/card.vue?macro=true";
import { default as codepayRHvQVe6wEtMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/deposit/codepay.vue?macro=true";
import { default as codepay2FwliPvWCWsMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/deposit/codepay2.vue?macro=true";
import { default as cryptoHCaj6DCyAwMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/deposit/crypto.vue?macro=true";
import { default as ewalletgJ6ISNsd3rMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/deposit/ewallet.vue?macro=true";
import { default as depositAhKUb36YDVMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/deposit.vue?macro=true";
import { default as betm3Y24lj2HLMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/history/bet.vue?macro=true";
import { default as transaction3bRvG1b6elMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/history/transaction.vue?macro=true";
import { default as historyNyue8gGCXAMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/history.vue?macro=true";
import { default as profilecZ5ITkXPe9Meta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/profile.vue?macro=true";
import { default as trading_45p2potGLKOn2jIMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/trading-p2p.vue?macro=true";
import { default as bankAX39n0J5tjMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/withdraw/bank.vue?macro=true";
import { default as cardpQGsvmB1ilMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/withdraw/card.vue?macro=true";
import { default as crypto0VAkY5l1VfMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/withdraw/crypto.vue?macro=true";
import { default as accountkOeQepYA9QMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account.vue?macro=true";
import { default as base_45buttonpX04jrUm49Meta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-button.vue?macro=true";
import { default as base_45count_45downSJx8Aqw8ARMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-count-down.vue?macro=true";
import { default as base_45dropdown_45radio49Zp1XCZNVMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-dropdown-radio.vue?macro=true";
import { default as base_45imglcoDHg7e5kMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-img.vue?macro=true";
import { default as base_45inputm7WDddFlaGMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-input.vue?macro=true";
import { default as base_45loadingEH5oX8P0l7Meta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-loading.vue?macro=true";
import { default as base_45modalCseqDkzLHPMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-modal.vue?macro=true";
import { default as base_45money_45inputuAGalUVca7Meta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-money-input.vue?macro=true";
import { default as base_45paginationOLr6HIxA26Meta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-pagination.vue?macro=true";
import { default as base_45select_45banksdp1vDJ5PIMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-select-bank.vue?macro=true";
import { default as game_45itemi1qf7oaR0qMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/game-item.vue?macro=true";
import { default as indexjwfpUbt4stMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/index.vue?macro=true";
import { default as _91type_93nS4Vbk47U4Meta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/game/[type].vue?macro=true";
import { default as gameB2eyqYGzWxMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/game.vue?macro=true";
import { default as _91alias_93rRAUQ2SSmZMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/gioi-thieu/[alias].vue?macro=true";
import { default as gioi_45thieuGd53YCBWflMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/gioi-thieu.vue?macro=true";
import { default as _91alias_93iwOHiY1HSLMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/huong-dan/[alias].vue?macro=true";
import { default as huong_45danaKLIPDMxkLMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/huong-dan.vue?macro=true";
import { default as indexKMvC6pUSuLMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/index.vue?macro=true";
import { default as _91id_93pLybfC7H57Meta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/khuyen-mai/[id].vue?macro=true";
import { default as indexiIA7QuZb3kMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/khuyen-mai/index.vue?macro=true";
import { default as _91id_93sT4aCVpyMuMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/khuyen-mai/the-loai/[id].vue?macro=true";
import { default as vipo7XEuirTzaMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/khuyen-mai/vip.vue?macro=true";
import { default as _91type_93ae42nxyZVzMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/livecasino/[type].vue?macro=true";
import { default as indexyZbEqgwYboMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/livecasino/index.vue?macro=true";
import { default as livecasinoLskcWHLAWuMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/livecasino.vue?macro=true";
import { default as reset_45passwordbxXylZK0nOMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/reset-password.vue?macro=true";
import { default as searchc8tDZqO1W7Meta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/search.vue?macro=true";
import { default as _91id_93nq37flplccMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/the-thao/[id].vue?macro=true";
import { default as indexC5he3j2UlgMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/the-thao/index.vue?macro=true";
import { default as indexIyF7EQxm8KMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/the-thao/lich-thi-dau/chi-tiet-tran-dau/index.vue?macro=true";
import { default as indexehVCDhTaHfMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/the-thao/lich-thi-dau/index.vue?macro=true";
import { default as _91alias_93Ql3FDDVVmjMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/tin-tuc/[alias].vue?macro=true";
import { default as indexmqBSK9wUKcMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/tin-tuc/index.vue?macro=true";
import { default as _91Slug_93iGaEzJaNoyMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/tin-tuc/the-loai/[Slug].vue?macro=true";
import { default as verify_45mailDAhNKI5pSZMeta } from "/Users/yoshi/Documents/Project/Brand/ps25-web/pages/verify-mail.vue?macro=true";
export default [
  {
    name: accountkOeQepYA9QMeta?.name ?? "account",
    path: accountkOeQepYA9QMeta?.path ?? "/account",
    children: [
  {
    name: bankkP9IyJrrxSMeta?.name ?? "account-bank",
    path: bankkP9IyJrrxSMeta?.path ?? "bank",
    meta: bankkP9IyJrrxSMeta || {},
    alias: bankkP9IyJrrxSMeta?.alias || [],
    redirect: bankkP9IyJrrxSMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/bank.vue").then(m => m.default || m)
  },
  {
    name: bonussaSpJ6Gh8PMeta?.name ?? "account-bonus",
    path: bonussaSpJ6Gh8PMeta?.path ?? "bonus",
    meta: bonussaSpJ6Gh8PMeta || {},
    alias: bonussaSpJ6Gh8PMeta?.alias || [],
    redirect: bonussaSpJ6Gh8PMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/bonus.vue").then(m => m.default || m)
  },
  {
    name: depositAhKUb36YDVMeta?.name ?? "account-deposit",
    path: depositAhKUb36YDVMeta?.path ?? "deposit",
    children: [
  {
    name: bankBUTcIuCzfgMeta?.name ?? "account-deposit-bank",
    path: bankBUTcIuCzfgMeta?.path ?? "bank",
    meta: bankBUTcIuCzfgMeta || {},
    alias: bankBUTcIuCzfgMeta?.alias || [],
    redirect: bankBUTcIuCzfgMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/deposit/bank.vue").then(m => m.default || m)
  },
  {
    name: cardslGOoJKhZxMeta?.name ?? "account-deposit-card",
    path: cardslGOoJKhZxMeta?.path ?? "card",
    meta: cardslGOoJKhZxMeta || {},
    alias: cardslGOoJKhZxMeta?.alias || [],
    redirect: cardslGOoJKhZxMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/deposit/card.vue").then(m => m.default || m)
  },
  {
    name: codepayRHvQVe6wEtMeta?.name ?? "account-deposit-codepay",
    path: codepayRHvQVe6wEtMeta?.path ?? "codepay",
    meta: codepayRHvQVe6wEtMeta || {},
    alias: codepayRHvQVe6wEtMeta?.alias || [],
    redirect: codepayRHvQVe6wEtMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/deposit/codepay.vue").then(m => m.default || m)
  },
  {
    name: codepay2FwliPvWCWsMeta?.name ?? "account-deposit-codepay2",
    path: codepay2FwliPvWCWsMeta?.path ?? "codepay2",
    meta: codepay2FwliPvWCWsMeta || {},
    alias: codepay2FwliPvWCWsMeta?.alias || [],
    redirect: codepay2FwliPvWCWsMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/deposit/codepay2.vue").then(m => m.default || m)
  },
  {
    name: cryptoHCaj6DCyAwMeta?.name ?? "account-deposit-crypto",
    path: cryptoHCaj6DCyAwMeta?.path ?? "crypto",
    meta: cryptoHCaj6DCyAwMeta || {},
    alias: cryptoHCaj6DCyAwMeta?.alias || [],
    redirect: cryptoHCaj6DCyAwMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/deposit/crypto.vue").then(m => m.default || m)
  },
  {
    name: ewalletgJ6ISNsd3rMeta?.name ?? "account-deposit-ewallet",
    path: ewalletgJ6ISNsd3rMeta?.path ?? "ewallet",
    meta: ewalletgJ6ISNsd3rMeta || {},
    alias: ewalletgJ6ISNsd3rMeta?.alias || [],
    redirect: ewalletgJ6ISNsd3rMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/deposit/ewallet.vue").then(m => m.default || m)
  }
],
    meta: depositAhKUb36YDVMeta || {},
    alias: depositAhKUb36YDVMeta?.alias || [],
    redirect: depositAhKUb36YDVMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/deposit.vue").then(m => m.default || m)
  },
  {
    name: historyNyue8gGCXAMeta?.name ?? "account-history",
    path: historyNyue8gGCXAMeta?.path ?? "history",
    children: [
  {
    name: betm3Y24lj2HLMeta?.name ?? "account-history-bet",
    path: betm3Y24lj2HLMeta?.path ?? "bet",
    meta: betm3Y24lj2HLMeta || {},
    alias: betm3Y24lj2HLMeta?.alias || [],
    redirect: betm3Y24lj2HLMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/history/bet.vue").then(m => m.default || m)
  },
  {
    name: transaction3bRvG1b6elMeta?.name ?? "account-history-transaction",
    path: transaction3bRvG1b6elMeta?.path ?? "transaction",
    meta: transaction3bRvG1b6elMeta || {},
    alias: transaction3bRvG1b6elMeta?.alias || [],
    redirect: transaction3bRvG1b6elMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/history/transaction.vue").then(m => m.default || m)
  }
],
    meta: historyNyue8gGCXAMeta || {},
    alias: historyNyue8gGCXAMeta?.alias || [],
    redirect: historyNyue8gGCXAMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: profilecZ5ITkXPe9Meta?.name ?? "account-profile",
    path: profilecZ5ITkXPe9Meta?.path ?? "profile",
    meta: profilecZ5ITkXPe9Meta || {},
    alias: profilecZ5ITkXPe9Meta?.alias || [],
    redirect: profilecZ5ITkXPe9Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: trading_45p2potGLKOn2jIMeta?.name ?? "account-trading-p2p",
    path: trading_45p2potGLKOn2jIMeta?.path ?? "trading-p2p",
    meta: trading_45p2potGLKOn2jIMeta || {},
    alias: trading_45p2potGLKOn2jIMeta?.alias || [],
    redirect: trading_45p2potGLKOn2jIMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/trading-p2p.vue").then(m => m.default || m)
  },
  {
    name: bankAX39n0J5tjMeta?.name ?? "account-withdraw-bank",
    path: bankAX39n0J5tjMeta?.path ?? "withdraw/bank",
    meta: bankAX39n0J5tjMeta || {},
    alias: bankAX39n0J5tjMeta?.alias || [],
    redirect: bankAX39n0J5tjMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/withdraw/bank.vue").then(m => m.default || m)
  },
  {
    name: cardpQGsvmB1ilMeta?.name ?? "account-withdraw-card",
    path: cardpQGsvmB1ilMeta?.path ?? "withdraw/card",
    meta: cardpQGsvmB1ilMeta || {},
    alias: cardpQGsvmB1ilMeta?.alias || [],
    redirect: cardpQGsvmB1ilMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/withdraw/card.vue").then(m => m.default || m)
  },
  {
    name: crypto0VAkY5l1VfMeta?.name ?? "account-withdraw-crypto",
    path: crypto0VAkY5l1VfMeta?.path ?? "withdraw/crypto",
    meta: crypto0VAkY5l1VfMeta || {},
    alias: crypto0VAkY5l1VfMeta?.alias || [],
    redirect: crypto0VAkY5l1VfMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account/withdraw/crypto.vue").then(m => m.default || m)
  }
],
    meta: accountkOeQepYA9QMeta || {},
    alias: accountkOeQepYA9QMeta?.alias || [],
    redirect: accountkOeQepYA9QMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: base_45buttonpX04jrUm49Meta?.name ?? "dev-base-button",
    path: base_45buttonpX04jrUm49Meta?.path ?? "/dev/base-button",
    meta: base_45buttonpX04jrUm49Meta || {},
    alias: base_45buttonpX04jrUm49Meta?.alias || [],
    redirect: base_45buttonpX04jrUm49Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-button.vue").then(m => m.default || m)
  },
  {
    name: base_45count_45downSJx8Aqw8ARMeta?.name ?? "dev-base-count-down",
    path: base_45count_45downSJx8Aqw8ARMeta?.path ?? "/dev/base-count-down",
    meta: base_45count_45downSJx8Aqw8ARMeta || {},
    alias: base_45count_45downSJx8Aqw8ARMeta?.alias || [],
    redirect: base_45count_45downSJx8Aqw8ARMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-count-down.vue").then(m => m.default || m)
  },
  {
    name: base_45dropdown_45radio49Zp1XCZNVMeta?.name ?? "dev-base-dropdown-radio",
    path: base_45dropdown_45radio49Zp1XCZNVMeta?.path ?? "/dev/base-dropdown-radio",
    meta: base_45dropdown_45radio49Zp1XCZNVMeta || {},
    alias: base_45dropdown_45radio49Zp1XCZNVMeta?.alias || [],
    redirect: base_45dropdown_45radio49Zp1XCZNVMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-dropdown-radio.vue").then(m => m.default || m)
  },
  {
    name: base_45imglcoDHg7e5kMeta?.name ?? "dev-base-img",
    path: base_45imglcoDHg7e5kMeta?.path ?? "/dev/base-img",
    meta: base_45imglcoDHg7e5kMeta || {},
    alias: base_45imglcoDHg7e5kMeta?.alias || [],
    redirect: base_45imglcoDHg7e5kMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-img.vue").then(m => m.default || m)
  },
  {
    name: base_45inputm7WDddFlaGMeta?.name ?? "dev-base-input",
    path: base_45inputm7WDddFlaGMeta?.path ?? "/dev/base-input",
    meta: base_45inputm7WDddFlaGMeta || {},
    alias: base_45inputm7WDddFlaGMeta?.alias || [],
    redirect: base_45inputm7WDddFlaGMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-input.vue").then(m => m.default || m)
  },
  {
    name: base_45loadingEH5oX8P0l7Meta?.name ?? "dev-base-loading",
    path: base_45loadingEH5oX8P0l7Meta?.path ?? "/dev/base-loading",
    meta: base_45loadingEH5oX8P0l7Meta || {},
    alias: base_45loadingEH5oX8P0l7Meta?.alias || [],
    redirect: base_45loadingEH5oX8P0l7Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-loading.vue").then(m => m.default || m)
  },
  {
    name: base_45modalCseqDkzLHPMeta?.name ?? "dev-base-modal",
    path: base_45modalCseqDkzLHPMeta?.path ?? "/dev/base-modal",
    meta: base_45modalCseqDkzLHPMeta || {},
    alias: base_45modalCseqDkzLHPMeta?.alias || [],
    redirect: base_45modalCseqDkzLHPMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-modal.vue").then(m => m.default || m)
  },
  {
    name: base_45money_45inputuAGalUVca7Meta?.name ?? "dev-base-money-input",
    path: base_45money_45inputuAGalUVca7Meta?.path ?? "/dev/base-money-input",
    meta: base_45money_45inputuAGalUVca7Meta || {},
    alias: base_45money_45inputuAGalUVca7Meta?.alias || [],
    redirect: base_45money_45inputuAGalUVca7Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-money-input.vue").then(m => m.default || m)
  },
  {
    name: base_45paginationOLr6HIxA26Meta?.name ?? "dev-base-pagination",
    path: base_45paginationOLr6HIxA26Meta?.path ?? "/dev/base-pagination",
    meta: base_45paginationOLr6HIxA26Meta || {},
    alias: base_45paginationOLr6HIxA26Meta?.alias || [],
    redirect: base_45paginationOLr6HIxA26Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-pagination.vue").then(m => m.default || m)
  },
  {
    name: base_45select_45banksdp1vDJ5PIMeta?.name ?? "dev-base-select-bank",
    path: base_45select_45banksdp1vDJ5PIMeta?.path ?? "/dev/base-select-bank",
    meta: base_45select_45banksdp1vDJ5PIMeta || {},
    alias: base_45select_45banksdp1vDJ5PIMeta?.alias || [],
    redirect: base_45select_45banksdp1vDJ5PIMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/base-select-bank.vue").then(m => m.default || m)
  },
  {
    name: game_45itemi1qf7oaR0qMeta?.name ?? "dev-game-item",
    path: game_45itemi1qf7oaR0qMeta?.path ?? "/dev/game-item",
    meta: game_45itemi1qf7oaR0qMeta || {},
    alias: game_45itemi1qf7oaR0qMeta?.alias || [],
    redirect: game_45itemi1qf7oaR0qMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/game-item.vue").then(m => m.default || m)
  },
  {
    name: indexjwfpUbt4stMeta?.name ?? "dev",
    path: indexjwfpUbt4stMeta?.path ?? "/dev",
    meta: indexjwfpUbt4stMeta || {},
    alias: indexjwfpUbt4stMeta?.alias || [],
    redirect: indexjwfpUbt4stMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: gameB2eyqYGzWxMeta?.name ?? "game",
    path: gameB2eyqYGzWxMeta?.path ?? "/game",
    children: [
  {
    name: _91type_93nS4Vbk47U4Meta?.name ?? "game-type",
    path: _91type_93nS4Vbk47U4Meta?.path ?? ":type()",
    meta: _91type_93nS4Vbk47U4Meta || {},
    alias: _91type_93nS4Vbk47U4Meta?.alias || [],
    redirect: _91type_93nS4Vbk47U4Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/game/[type].vue").then(m => m.default || m)
  }
],
    meta: gameB2eyqYGzWxMeta || {},
    alias: gameB2eyqYGzWxMeta?.alias || [],
    redirect: gameB2eyqYGzWxMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/game.vue").then(m => m.default || m)
  },
  {
    name: gioi_45thieuGd53YCBWflMeta?.name ?? "gioi-thieu",
    path: gioi_45thieuGd53YCBWflMeta?.path ?? "/gioi-thieu",
    children: [
  {
    name: _91alias_93rRAUQ2SSmZMeta?.name ?? "gioi-thieu-alias",
    path: _91alias_93rRAUQ2SSmZMeta?.path ?? ":alias()",
    meta: _91alias_93rRAUQ2SSmZMeta || {},
    alias: _91alias_93rRAUQ2SSmZMeta?.alias || [],
    redirect: _91alias_93rRAUQ2SSmZMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/gioi-thieu/[alias].vue").then(m => m.default || m)
  }
],
    meta: gioi_45thieuGd53YCBWflMeta || {},
    alias: gioi_45thieuGd53YCBWflMeta?.alias || [],
    redirect: gioi_45thieuGd53YCBWflMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/gioi-thieu.vue").then(m => m.default || m)
  },
  {
    name: huong_45danaKLIPDMxkLMeta?.name ?? "huong-dan",
    path: huong_45danaKLIPDMxkLMeta?.path ?? "/huong-dan",
    children: [
  {
    name: _91alias_93iwOHiY1HSLMeta?.name ?? "huong-dan-alias",
    path: _91alias_93iwOHiY1HSLMeta?.path ?? ":alias()",
    meta: _91alias_93iwOHiY1HSLMeta || {},
    alias: _91alias_93iwOHiY1HSLMeta?.alias || [],
    redirect: _91alias_93iwOHiY1HSLMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/huong-dan/[alias].vue").then(m => m.default || m)
  }
],
    meta: huong_45danaKLIPDMxkLMeta || {},
    alias: huong_45danaKLIPDMxkLMeta?.alias || [],
    redirect: huong_45danaKLIPDMxkLMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/huong-dan.vue").then(m => m.default || m)
  },
  {
    name: indexKMvC6pUSuLMeta?.name ?? "index",
    path: indexKMvC6pUSuLMeta?.path ?? "/",
    meta: indexKMvC6pUSuLMeta || {},
    alias: indexKMvC6pUSuLMeta?.alias || [],
    redirect: indexKMvC6pUSuLMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pLybfC7H57Meta?.name ?? "khuyen-mai-id",
    path: _91id_93pLybfC7H57Meta?.path ?? "/khuyen-mai/:id()",
    meta: _91id_93pLybfC7H57Meta || {},
    alias: _91id_93pLybfC7H57Meta?.alias || [],
    redirect: _91id_93pLybfC7H57Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/khuyen-mai/[id].vue").then(m => m.default || m)
  },
  {
    name: indexiIA7QuZb3kMeta?.name ?? "khuyen-mai",
    path: indexiIA7QuZb3kMeta?.path ?? "/khuyen-mai",
    meta: indexiIA7QuZb3kMeta || {},
    alias: indexiIA7QuZb3kMeta?.alias || [],
    redirect: indexiIA7QuZb3kMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/khuyen-mai/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93sT4aCVpyMuMeta?.name ?? "khuyen-mai-the-loai-id",
    path: _91id_93sT4aCVpyMuMeta?.path ?? "/khuyen-mai/the-loai/:id()",
    meta: _91id_93sT4aCVpyMuMeta || {},
    alias: _91id_93sT4aCVpyMuMeta?.alias || [],
    redirect: _91id_93sT4aCVpyMuMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/khuyen-mai/the-loai/[id].vue").then(m => m.default || m)
  },
  {
    name: vipo7XEuirTzaMeta?.name ?? "khuyen-mai-vip",
    path: vipo7XEuirTzaMeta?.path ?? "/khuyen-mai/vip",
    meta: vipo7XEuirTzaMeta || {},
    alias: vipo7XEuirTzaMeta?.alias || [],
    redirect: vipo7XEuirTzaMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/khuyen-mai/vip.vue").then(m => m.default || m)
  },
  {
    path: livecasinoLskcWHLAWuMeta?.path ?? "/livecasino",
    children: [
  {
    name: _91type_93ae42nxyZVzMeta?.name ?? "livecasino-type",
    path: _91type_93ae42nxyZVzMeta?.path ?? ":type()",
    meta: _91type_93ae42nxyZVzMeta || {},
    alias: _91type_93ae42nxyZVzMeta?.alias || [],
    redirect: _91type_93ae42nxyZVzMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/livecasino/[type].vue").then(m => m.default || m)
  },
  {
    name: indexyZbEqgwYboMeta?.name ?? "livecasino",
    path: indexyZbEqgwYboMeta?.path ?? "",
    meta: indexyZbEqgwYboMeta || {},
    alias: indexyZbEqgwYboMeta?.alias || [],
    redirect: indexyZbEqgwYboMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/livecasino/index.vue").then(m => m.default || m)
  }
],
    name: livecasinoLskcWHLAWuMeta?.name ?? undefined,
    meta: livecasinoLskcWHLAWuMeta || {},
    alias: livecasinoLskcWHLAWuMeta?.alias || [],
    redirect: livecasinoLskcWHLAWuMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/livecasino.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordbxXylZK0nOMeta?.name ?? "reset-password",
    path: reset_45passwordbxXylZK0nOMeta?.path ?? "/reset-password",
    meta: reset_45passwordbxXylZK0nOMeta || {},
    alias: reset_45passwordbxXylZK0nOMeta?.alias || [],
    redirect: reset_45passwordbxXylZK0nOMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: searchc8tDZqO1W7Meta?.name ?? "search",
    path: searchc8tDZqO1W7Meta?.path ?? "/search",
    meta: searchc8tDZqO1W7Meta || {},
    alias: searchc8tDZqO1W7Meta?.alias || [],
    redirect: searchc8tDZqO1W7Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/search.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nq37flplccMeta?.name ?? "the-thao-id",
    path: _91id_93nq37flplccMeta?.path ?? "/the-thao/:id()",
    meta: _91id_93nq37flplccMeta || {},
    alias: _91id_93nq37flplccMeta?.alias || [],
    redirect: _91id_93nq37flplccMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/the-thao/[id].vue").then(m => m.default || m)
  },
  {
    name: indexC5he3j2UlgMeta?.name ?? "the-thao",
    path: indexC5he3j2UlgMeta?.path ?? "/the-thao",
    meta: indexC5he3j2UlgMeta || {},
    alias: indexC5he3j2UlgMeta?.alias || [],
    redirect: indexC5he3j2UlgMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/the-thao/index.vue").then(m => m.default || m)
  },
  {
    name: indexIyF7EQxm8KMeta?.name ?? "the-thao-lich-thi-dau-chi-tiet-tran-dau",
    path: indexIyF7EQxm8KMeta?.path ?? "/the-thao/lich-thi-dau/chi-tiet-tran-dau",
    meta: indexIyF7EQxm8KMeta || {},
    alias: indexIyF7EQxm8KMeta?.alias || [],
    redirect: indexIyF7EQxm8KMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/the-thao/lich-thi-dau/chi-tiet-tran-dau/index.vue").then(m => m.default || m)
  },
  {
    name: indexehVCDhTaHfMeta?.name ?? "the-thao-lich-thi-dau",
    path: indexehVCDhTaHfMeta?.path ?? "/the-thao/lich-thi-dau",
    meta: indexehVCDhTaHfMeta || {},
    alias: indexehVCDhTaHfMeta?.alias || [],
    redirect: indexehVCDhTaHfMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/the-thao/lich-thi-dau/index.vue").then(m => m.default || m)
  },
  {
    name: _91alias_93Ql3FDDVVmjMeta?.name ?? "tin-tuc-alias",
    path: _91alias_93Ql3FDDVVmjMeta?.path ?? "/tin-tuc/:alias()",
    meta: _91alias_93Ql3FDDVVmjMeta || {},
    alias: _91alias_93Ql3FDDVVmjMeta?.alias || [],
    redirect: _91alias_93Ql3FDDVVmjMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/tin-tuc/[alias].vue").then(m => m.default || m)
  },
  {
    name: indexmqBSK9wUKcMeta?.name ?? "tin-tuc",
    path: indexmqBSK9wUKcMeta?.path ?? "/tin-tuc",
    meta: indexmqBSK9wUKcMeta || {},
    alias: indexmqBSK9wUKcMeta?.alias || [],
    redirect: indexmqBSK9wUKcMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: _91Slug_93iGaEzJaNoyMeta?.name ?? "tin-tuc-the-loai-Slug",
    path: _91Slug_93iGaEzJaNoyMeta?.path ?? "/tin-tuc/the-loai/:Slug()",
    meta: _91Slug_93iGaEzJaNoyMeta || {},
    alias: _91Slug_93iGaEzJaNoyMeta?.alias || [],
    redirect: _91Slug_93iGaEzJaNoyMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/tin-tuc/the-loai/[Slug].vue").then(m => m.default || m)
  },
  {
    name: verify_45mailDAhNKI5pSZMeta?.name ?? "verify-mail",
    path: verify_45mailDAhNKI5pSZMeta?.path ?? "/verify-mail",
    meta: verify_45mailDAhNKI5pSZMeta || {},
    alias: verify_45mailDAhNKI5pSZMeta?.alias || [],
    redirect: verify_45mailDAhNKI5pSZMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps25-web/pages/verify-mail.vue").then(m => m.default || m)
  }
]